const routes = {
  HOME: '/',
  ANTECIPACAO_HOME_CREDITO: '/antecipacao-home-credito',
  AUTORIZACAO: '/autorizacao',
  CONTA_SALARIO: '/conta-salario',
  NAO_AUTORIZADO_SALDO: '/nao-autorizado/saldo',
  NAO_AUTORIZADO_RESULTADO: '/nao-autorizado/resultado',
  ERROR: '/error',
  ESPERA: '/espera',
  PROPOSTA: '/proposta',
  AUTORIZADO_RESULTADO: '/autorizado/resultado',
  AUTORIZADO_REVISAO: '/autorizado/revisao',
  CONTRATO_CCB: '/contrato/ccb',
  RESUMO_CONTRATO: '/resumo-contrato',
  AUTORIZADO_CONFIRMACAO: '/autorizado/confirmacao',
  ONBOARDING: '/onboarding',
  SAIBA_MAIS: '/saiba-mais',
  AUTORIZADO_CONTRATACAO: '/autorizado/contratacao',
  DEBITO_AUTOMATICO: '/debito-automatico',
  DEBITO_AUTOMATICO_PERGUNTAS_FREQUENTES: '/debito-automatico/perguntas-frequentes',
  DEBITO_AUTOMATICO_ATIVADO: '/debito-automatico/ativado',
  DEBITO_AUTOMATICO_DESATIVADO: '/debito-automatico/desativado',
  LIMITE_FLEXIVEL: '/limite-flexivel',
  LIMITES: '/limites',
}

export default routes
